<template>
  <div>
    <!-- 采购转换率分析-->
    <filter-view :loading="loading" @submit="loadEchart">
      <retail-Filter :show-opt="showOpt" :query="query" :rank="rank" @init="loadEchart" />
    </filter-view>
    <div class="result">
      <template v-if="store && store.length > 0">
        <div class="padding-10 fs-small fc-g">数据列表</div>
        <div class="round-panel bc-w">
          <div v-for="o in pagedStore" :key="o.id" class="padding-05 sep-b">
            <div class="h c wrap fs-small lh-150">
              <div class="padding-05" style="width: 100%">
                排名：{{ o.rank || "-" }}
                <br />
                地区/经销商：{{ o.name || "-" }}
                <br />
                零售单数量：{{ o.saleFormCount || "-" }}
                <br />
                已采购零售单数量：{{ o.purchaseOrderFormCount || "-" }}
                <br />
                采购单数量：{{ o.purchaseFormCount || "-" }}
                <br />
                无来源采购单数量：{{ o.unsourcePurchaseFormCount || "-" }}
                <br />
                <div>
                  转换率：
                  <span v-if="o.purchaseRatio">{{ Math.round(o.purchaseRatio * 10000) / 100 }}%</span>
                  <span v-else>--</span>
                </div>
              </div>
            </div>
          </div>
          <div class="ta-c">
            <el-pagination class="h5-pagination" :total="totalElements" :current-page.sync="page" :page-size="size" layout="prev, pager, next" hide-on-single-page />
          </div>
        </div>
      </template>
      <div class="fc-g ta-c padding-10" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
    </div>
  </div>
</template>

<script>
import filterView from "../assembly/filter";
import retailFilter from "../assembly/filter/distributors";
import request from "@/utils/request";
export default {
  components: {
    filterView,
    retailFilter,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      page: 1,
      size: 6,
      totalElements: null,
      store: [], //图表数据
      showOpt: {
        showDimension: false, //分析维度隐藏
        showTimeRange: true, //时间范围
        showLevel: false, //汇总级别
        showYearonyear: false, //同比显示
        showCommodity: false, //指定商品
        showRangGoods: false, //商品范围
        showMonth: false, //环比隐藏
        showRank: true, //显示排名对象
        showRankGist: false, //显示排名依据
        showCompareyear: false,
        showCategory: false, //对比类型
        showReported: false, //只统计已上报
      },
      query: {
        addressType: "area",
        treeNodeId: null,
        treeNodeType: null,
        dateGroup: "day",
        StandardAreaGroup: null,
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        page: 0,
        size: 10000,
      },
      rank: {
        rankDefines: [
          {
            name: "区域",
            value: { AreaGroupType: "area", shopAreaGroup: "area" },
          },
          {
            name: "经销商",
            value: { AreaGroupType: "area", shopAreaGroup: "distributor" },
          },
          {
            name: "省",
            value: { AreaGroupType: "standard", cityAreaGroup: "province" },
          },
          {
            name: "市",
            value: { AreaGroupType: "standard", cityAreaGroup: "city" },
          },
        ],
        multParams: 0,
      },
    };
  },
  watch: {
    store() {
      this.page = 1;
    },
  },
  computed: {
    pagedStore() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.store.length) {
        len = this.store.length - start;
      }
      return this.store.slice(start, start + len);
    },
  },
  methods: {
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      Object.assign(data, this.rank.rankDefines[this.rank.multParams].value);
      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();
      if (data.addressType == "standard") {
        if (this.query.StandardAreaGroup == 0) {
          data.StandardAreaGroup = "country";
        }
        if (this.query.StandardAreaGroup == 1) {
          data.StandardAreaGroup = "province";
        }
        if (this.query.StandardAreaGroup == 2) {
          data.StandardAreaGroup = "city";
        }
        if (this.query.StandardAreaGroup == 3) {
          data.StandardAreaGroup = "district";
        }
        delete data.treeNodeType;
      } else {
        data.treeNodeType = this.query.treeNodeType;
        delete data.StandardAreaGroup;
      }
      delete data.goods;
      return data;
    },
    loadEchart() {
      let data = Object.assign({}, this.makeParams());
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/purchase/sale/ratio",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.store = res.content;
          this.totalElements = res.totalElements;
        })
        .catch((err) => {
          this.store = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>